.list-shopee {
    .table thead > tr {
        background-color: #fff;
    }
    .table tbody > tr {
        background-color: #DEE2E6;
    }
    .table tbody > tr:hover > * {
        --bs-table-accent-bg: unset;
        color: unset;
    }
    .table thead tr th:last-child,
    .table tbody tr td:last-child {
        width: 10rem;
        text-align: center;
    }
    .table tbody > tr:nth-of-type(odd) > * {
        --bs-table-accent-bg: unset;
        color: unset;
    }
    .line {
        border-top: 2px solid #fff;
    }
    .table tbody > tr.line-failed {
        background-color: #FDDEE5;
        color: #FA5C7C;
        .col-icons .icon-delete-order {
            color: #FA5C7C;
        }
    }
    .table tbody > tr.line-completed {
        background-color: #51C4A7;
        color: #FFFFFF;
        .icon-delete-order {
            color: #FFFFFF;
        }
    }
    .icon-delete-order {
        color: $color-lead;
        font-size: 1.2rem;
        padding: 0 0.45rem;
        margin: -1.1rem 0;
    }
    .icon-info-failed {
        color: #FA5C7C;
        font-size: 1rem;
        padding: 0 0.45rem;
    }
}
