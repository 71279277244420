.pagination-default {
  display: flex;
  padding-left: 0;
  list-style: none;
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #fff;
    margin: 0 5px 0 5px;
    cursor: pointer;
    a {
      color: #313a46;
      text-decoration: none;
      border: none;
    }
  }
  li:hover {
    border: solid 1px #eef2f7;
    border-radius: 30px;
  }
}

.active-pagination {
  border: solid 1px $color-default-2;
  border-radius: 30px;
  background-color: $color-default-2 !important;
  a {
    color: #fff !important;
  }
}

.disabled-pagination {
  display: none !important;
}

.disabled-pagination-link {
  pointer-events: none !important;
  cursor: default !important;
  text-decoration: none !important;
}
