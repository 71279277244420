//
// tables.scss
//

//Table centered (Custom)
.table-centered {
  th,
  td {
    vertical-align: middle !important;
  }
}

// Custom table components (Custom)
.table {
  color: #333333;
  font-weight: 600;
  font-size: 12px;
  thead {
    th {
      color: $color-lead;
      font-weight: $font-weight-semi-bold;
    }
  }
  tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: #f0f5f5;
    color: $color-dark-green;
  }
  tbody > tr:hover > * {
    --bs-table-accent-bg: #E4E6EF;
    color: $color-dark-green;
  }
  .table-user {
    img {
      height: 30px;
      width: 30px;
    }
  }
  .action-icon {
    color: $gray-600;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
      color: $gray-700;
    }
  }
}

.table > :not(caption) > * > {
  th {
    padding: 0.55rem 0.65rem !important;
  }
}

.table-striped > tbody > tr {
  td {
    padding: 0.55rem 0.65rem !important;
    vertical-align: middle;
  }
}

// Tables fluid
.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}
