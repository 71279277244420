//
// buttons.scss
//

.btn {
  font-size: 12px;
  font-weight: 600;
    .mdi {
        &:before {
            line-height: initial;
        }
    }
}

.btn-rounded {
    border-radius: 2em;
}

// Button Shadows
@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include button-shadow($value);
    }
}

.btn-semi-bold {
  font-weight: 600;
}

.btn:focus {
 box-shadow: none !important;
}

.btn-width-total {
  width: 100%;
}

.btn-height-double {
  height: 4.2em;
}

.btn-warning {
  color: #FFF !important;
}
.btn-warning:hover {
  color: #FFF !important;
}
