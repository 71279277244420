.col-border {
  border-left: 0.1em solid #9AA1AB29;

}

.card-body-packages {
  padding: 1.5rem 1rem;
}

.btn-package-receiver {
  padding: 0 10px !important;
  color: #004D59 !important;
}

.icon-add-package {
  padding-left: 3px;
  font-size: 1.3em;
}

.btn-finalize-receiver {
  // padding-top: 3px;
  // padding-bottom: 3px;
  position: relative;

  i {
    font-size: 1.8em;
    position: absolute;
    top: 1px;
  }
  .spinner {
    margin: -2px 8px;
  }
}

.icon-delete-package {
  color: #FA5C7C;
  font-size: 1.2em;
  padding-top: 4px !important;
}
.icon-delete-package:hover {
  color: #FA5C7C;
}

.btn-delivery {
  width: 100%;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;

  .package {
    padding: 7px;
    background: #FFF;
    color: #333333;
    border-radius: 0.15rem;
  }
  .destination, .delivery {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .delivery {
    i {
      padding-top: 5px;
      font-size: 1.5em;
    }
  }
}

.btn-receive {
  width: 100%;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  .col-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .col-name {
    justify-content: start;
  }
  i {
    font-size: 2.5em;
  }
}

.vertical-line-container {
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    display: none;
  }
}

.vertical-line {
  width: 1px;
  height: 40%;
  border-right: 1px solid #FFFFFF;

  @media (max-width: 767px) {
    display: none;
  }
}

.icon-search-package {
  font-size: 1.3em;
  color: #FFF;
  margin-left: 1.7em;
  margin-right: 1.7em;
}

.description-end-costumer {
  font-size: 12px;
  font-weight: 600;
  color: #004D59;
  margin-top: -13px;
}

.title-end-costumer {
  font-size: 20px;
  font-weight: 600;
  color: #004D59;
}

.data-collection {
  font-size: 12px;
  font-weight: 600;
  color: #004D59;
}

.label-packages {
  font-size: 12px;
  font-weight: 600;
  color: #004D59;
}

.btn-round {
  border-radius: 50% !important;
  padding: 0px 5px !important;

  i {
    font-size: 1.3em;
    color: #FFF !important;
  }
}

.list-hidden {
  border: 1px solid #004D59;
  border-radius: 6px;
  table {
    margin: 0;
  }
  table tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
  }

  table tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
  }
  thead {
    background-color: #CFD4DA;
  }
}

.list-hidden-secondary {
  border: 1px solid #03AD75;
}
