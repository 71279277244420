.receipt-container {
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #8e9ca8;
}

.page-subtitle {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  line-height: 1.4;
  color: $color-default-2;
}

.text-bold {
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  margin-top: 15px !important;
}

.simple-text {
  color: #8e9ca8;
  line-height: 1.5;
  margin-bottom: 3px;
}

.btn-print {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  padding: 2px;
  font-size: 12px;
}

.text-header {
  font-size: 12px;
  font-weight: bold;
  color: #333333;
}

.tracking-canceled {
  padding: 20px;
  margin: 20px 0;
  border-radius: 13px;
  text-align: center;
  background: white;
  color: #E87960;
  font-size: 16px;
  font-weight: 600;
}



.time-line-container {
  justify-content: center;
  border-radius: 4px;
  background: white;
  padding-top: 1rem;
  margin: 10px 0;
}

.city-name {
  font-size: 14px;
  font-weight: 600;
  color: #004D59;
  margin: 0;
}

.tracking-state-name {
  color: #004D59;
  font-weight: 500;
}

.tracking-state-date {
  color: rgb(139, 139, 139);
}

.time-line-down {
  display: flex;
  text-align: center;
}

.time-lineup {
  display: flex;
  text-align: center;
  .progress {
    height: 4em;
    z-index: 1;
    background-color: transparent;
    padding-top: 3.8em;

    .progress-bar {
      background-color: #03AD75;
      flex-direction: row;
      justify-content: end;
      border-radius: 0;

      .truck-label {
        margin-top: -34px !important;
        position: absolute !important;
        transform: rotateY(180deg) !important;
        margin-right: 10px;
        color: #03AD75;
      }
      .truck-label-not-margin {
        margin-right: -35px !important;
      }
      .angle-right-label {
        margin-top: -21px !important;
        position: absolute !important;
        margin-right: -12px;
        color: #03AD75;
      }
    }
  }
}

.progress::after {
  content: '';
  border-bottom: 1px dashed #8E9CA8;
  height: 1px;
  width: $widthBar;
}

.timeline-dashed-path {
  border: none;
  content: '';
  border-top: 1px dashed #8E9CA8;
  height: 1px;
  width: 66.8% !important;
  margin-top: 2.5rem;
  z-index: 1;
}

.timeline-path {
  border: none;
  content: '';
  border-top: 4px solid #03AD75;
  height: 1px;
  z-index: 3;
}

.link-container {
  font-weight: 600;
  text-align: center;
  color: #004D59;
  .link {
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
    color: #004D59;
  }
}

.content-tracking {
  overflow: auto;
  width: 100%;
}

.content-tracking-overflow {
  min-width: 550px;
  position: relative;
  padding: 0 5px;
}

.text-info {
  color: #004d59;
  font-size: 14px;
  margin: 0;
  padding: 0;
  line-height: 1.6;
}

.buton-print {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: #8e9ca8;
  color: white;
  text-decoration: none;
  border-radius: 15px;
  font-size: 14px;
  height: 40px;
  width: 200px;

  :hover {
    color: white;
    filter: brightness(0.9);
  }
}

.card-container {
  display: flex;
  flex-direction: column;
  padding: 5px 20px;
  margin-bottom: 10px;
  height: 100%;
  box-shadow: 0px 0px 35px #9aa1ab29;
  border-radius: 15px;
  max-width: 500px;
}

.title-content {
  display: flex;
  gap: 14px;
  align-items: center;
}

.title-receipt {
  color: #004d59;
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.content-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 42px;
}

.btn-receipt {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  border: none;
}

.icon-btn {
  margin: 0;
  padding: 0;
  color: #004d59;
  font-size: 11px;
  font-weight: 600;
}

.content-viewMore {
  max-width: 100%;
  color: #8e9ca8;
  font-size: 11px;
  padding: 10px 54px 20px 54px;
}

.text-receipt {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.6;
  color: #004d59;
}

.subtitle-receipt {
  color: #03ad75;
}

.secondary-text {
  color: #8e9ca8;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
}

.icon-solid::before {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: blue;
  border-radius: 15px;
  width: 25px;
  height: 25px;
}

.icon-solid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 5px;
    text-align: center;
  }
}

.info {
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.info-header {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid #8E9CA8;
  border-bottom: 0;
  background-color: white;

  @media (max-width: 768px) {
    border-bottom: 2px solid #8E9CA8;
    border-right: 0;
    margin-bottom: 10px;
  }
}

.print-btn {
  max-width: 196px;
  width: 100%;
  height: 30px;
  padding: 6px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  background-color: #004D59;
  text-decoration: none;
  color: white;
  border-radius: 2px;
  border: none;
}

.print-btn:hover {
  color: white;
  background-color: #02424D;
}

.opening-hours {
  .header {
    margin-bottom: 0.5rem;
    color: $color-lead;
    font-weight: 600;
    font-size: 12px;
    margin-right: 2px;
    > div:nth-last-child(2) {
      padding-left: 0;
    }
  }
  .title {
    color: $color-lead;
    font-weight: 600;
    font-size: 12px;
  }
  .even-row {
    background-color: #F0F4F5;
    align-items: center;
    padding: 2px;
    margin-right: 2px;
    > div:nth-last-child(2) {
      padding-left: 0;
    }
    > div:last-child {
      padding-right: 0;
    }
  }
  .odd-row {
    align-items: center;
    padding: 2px;
    margin-right: 2px;
    > div:nth-last-child(2) {
      padding-left: 0;
    }
    > div:last-child {
      padding-right: 0;
    }
  }
}

@media (max-width: 500px) {
  div.scroll-div {
    width: 100%;
    overflow-x: hidden;
  }
  div.scroll-div {
    overflow-x: auto;
  }
  div.scroll-horizontal-div {
    width: 500px;
  }
}

.marketplace-operation {
  .title {
    margin-bottom: 0.5rem;
    color: $color-lead;
    font-weight: 600;
    font-size: 12px;
  }
  .table-responsive {
    max-height: 200px;
    table {
      margin: 0;
    }
  }
}
