.icon-map {
  color: #24b58d;
}

.filter-requests {
  margin-bottom: 20px;
}

.button-inline-requests {
  margin-top: 2em;
  width: 100%;
}

.button-right {
  float: right;
}

.content-signature,
.content-image-voucher {
  border: 5px solid #d1f3f3;
  margin: 1.8rem 0 1.8em 0;
  padding: 10px 10px 25px 10px;
  max-width: 490px;

  img {
    width: 100%;
    height: 100%;
  }
  button {
    margin-top: -10px;
  }
  .btn-check:focus + .btn,
  .btn:focus {
    box-shadow: none;
  }
}

.shopee-arrow {
  cursor: pointer;
}
