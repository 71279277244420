.button-term {
  color: #6c757d !important;
  padding: 0 !important;
  text-decoration: underline;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-term:hover {
  color: #6c757d !important;
}
