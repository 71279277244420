.react-tag-input {
  font-size: 0.9rem !important;
  color: #6c757d !important;
}

.react-tag-input__input {
  color: #6c757d !important;
}

.react-tag-input__tag {
  background: #A6E9EC !important;
  font-size: 0.9rem !important;
}

.react-tag-input__tag__remove {
  background: #A6E9EC !important;
}

.react-tag-input__input::placeholder, .react-tag-input__input:-moz-placeholder, .react-tag-input__input:-ms-input-placeholder, .react-tag-input__input::-moz-placeholder, .react-tag-input__input::-webkit-input-placeholder {
  color: #98a6ad !important;
}
