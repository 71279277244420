input {
  font-size: 11px!important;
}
.container-input-date-range {
  position: relative;
  i {
    position: absolute;
    right: 5px;
    top: 1.33em;
    z-index: 9;
    font-size: 1.5em;
  }
}

.input-date-range-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-left: 10px; /* Ajuste a margem conforme necessário */
}

.input-date-range {
  border-width: 1px;
  border-color: #dfe3e7;
  color: #8E9CA8;
  border-style: solid;
  padding: 7px 10px 7px 10px;
  width: 100% !important;
  outline: 0;
}

.date-range-error {
  border-width: 1px;
  border-color: #fa5c7c;
}

.input-check {
  input {
    border: 1px solid #004D59 !important;
    border-color: #004D59;
    width: 1.2em;
    height: 1.2em;
    :focus, :hover, :active, :checked {
      border-color: #004D59 !important;
    }
  }
}
