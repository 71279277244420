//
// language-selector.scss
//

.language-selector-container {
  display: 'flex';
  padding: '5px';
  gap: '5px';
}

.language-selector-input {
  position: 'absolute';
  opacity: 0;
}

.language-selector-label {
  cursor: pointer;
}

.language-selector-desactive {
  filter: brightness(50%);
}
