
.icon-check-circle {
  font-size: 1.8em;
  margin: -8px;
  padding-top: 0;
  padding-bottom: 0;
}

.icon-check-circle-select {
  color: #03AD75;
}
.icon-check-circle-select:hover {
  color: #03AD75;
}

.text-right {
  text-align: right;
}

.text-bold {
  font-weight: bold;
}

.table-select {
  .table-responsive {
    max-height: 50vh;
    .sticky {
      position: sticky;
      top: 0;
      background-color: #F1F3FA;
      z-index: 1;
    }
  }
}

