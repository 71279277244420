.table-platforms-order {
    font-weight: 600;
    font-size: 12px;
    color: $color-lead;
    margin: 0 1em;

    .scrolling {
        max-height: 400px;
        overflow-x: hidden;
        padding: 0 10px;
        margin: 0 -10px;
    }
   
    .line-platforms-order {
        background-color: #DEE2E6;
        display: flex;
        align-items: center;
        margin-top: 1px;
        padding: 0.45rem 0;

        .col-icons {
            display: flex;
            align-items: center;
            justify-content: end;
            .spinner {
                width: 1rem;
                height: 1rem;
                margin: 0.5rem;
            }
            .icon-delete-order {
                color: $color-lead;
                font-size: 1.2rem;
                padding: 0 0.45rem;
            }
        }
        .icon-info-failed {
            color: #FA5C7C;
            font-size: 1rem;
            padding: 0 0.45rem;
        }
    }
    .line-completed {
        background-color: #51C4A7;
        color: #FFFFFF;
        .col-icons .icon-delete-order {
            color: #FFFFFF;
        }
    }
    .line-failed {
        background-color: #FDDEE5;
        color: #FA5C7C;
        .col-icons .icon-delete-order {
            color: #FA5C7C;
        }
    }
    .line-empty {
        background-color: #DEE2E6;
        margin-top: 1px;
        padding: 0.8rem 0;
    }
}

.spinner-include {
    width: 1rem;
    height: 1rem;
    margin: -2px;
    margin-left: 2px;
}